<route>
  {
  "name": "reset-password-step2",
  "meta": {
  "auth": false
  }
  }
</route>

<template>
  <div class="flex-column flex-grow">
    <div class="wrapper">
      <ui-form
        class="form"
        :model="values"
        :rules="rules"
        :hideRequiredMark="true"
        @form="form = $event"
        @validate="handleValidate"
        @submit.prevent="handleSubmit"
      >
        <img
          src="@/assets/images/reset_pass.png"
          alt="anonymous-icon"
          class="anonymous-icon mb-24"
        />

        <ui-form-item label="New password" prop="password">
          <ui-password-input
              class="wfix"
              v-model:value="values.password" />
        </ui-form-item>

        <ui-form-item label="Re-enter your new password" prop="passwordCopy">
          <ui-password-input
              class="wfix"
              v-model:value="values.passwordCopy" />
        </ui-form-item>
      </ui-form>

      <ui-button class="wfix" @click="handleSubmit">
        {{$t('reset-password-step2.change_password')}}
      </ui-button>
    </div>

    <div class="text2 footer center">
      <ui-link to="/login">{{$t('reset-password-step2.login')}}</ui-link>
    </div>
  </div>
</template>

<script>
import { authManagementClient } from '@/plugins/feathersClient';
import UiForm from '../components/ui/UiForm';
import UiFormItem from '../components/ui/UiFormItem';
import UiPasswordInput from '../components/ui/UiPasswordInput';
import UiButton from '../components/ui/UiButton';
import UiLink from '../components/ui/UiLink';

export default {
  layout: 'auth',
  name: 'reset-password-step2',
  components: {
    UiLink,
    UiForm,
    UiFormItem,
    UiPasswordInput,
    UiButton
  },
  data() {
    return {
      form: {},
      values: {
        token: '',
        password: '',
        passwordCopy: ''
      },

      rules: {
        password: [
          {
            min: 8,
            required: true,
            message: 'Must be at least 8 characters',
            trigger: 'change'
          }
        ],
        passwordCopy: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('Please input the password again'));
              } else if (value !== this.values.password) {
                callback(new Error("Two inputs don't match!"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ]
      },
      btnDisabled: true
    };
  },
  mounted() {
    this.values.token = this.$route.query.token;
  },
  methods: {
    handleValidate(/* propName, isValid, errorMsg */) {
      // prop name of the form item being validated,
      // whether validation is passed and the error message if not
      // this.btnDisabled = this.form.fields.reduce((result, field) => result || field.validateState !== 'success', false);
    },
    async handleSubmit() {
      try {
        await this.form.validate();
        await authManagementClient.resetPwdLong(this.values.token, this.values.password);

        this.$router.push({ path: '/login' });
      } catch (err) {
        this.$notify.parseFeathersErrors(err);
      }
    },
    resetForm() {
      this.form.resetFields();
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 45px 70px;
  align-items: center;
}

.form {
  width: 100%;
  min-height: 280px;
}

.footer {
  padding: 32px 0 16px 0;
}
.wfix {
  width: 352px;
}
.center {
  text-align: center;
}
</style>
